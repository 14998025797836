import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from 'react';
import {toast} from "react-toastify";
import api from "../../../api/api";
import {useStore} from "../../../hooks/useStore";
import InputBox from "./input_box";
import classNames from "classnames";
import s from './styles.module.css';

const FigureBox2 = observer(({
                                 id,
                                 socketData,
                                 judgleStatus,
                                 setOpenDialogCustom,
                                 setCoachTypeId,
                                 setCoachTypeNumber,
                             }) => {
    const {figure} = useStore()
    const [figureData, setFigureData] = useState(figure)
    const [updateInput, setUpdateInput] = useState(false)
    const blockRefs = useRef([]);
    const [blockScrollPositions, setBlockScrollPositions] = useState(JSON.parse(localStorage.getItem(`blockScrollPositions-${id}`)) ||
        [
            {index: 0, scrollTop: 0},
            {index: 1, scrollTop: 0},
            {index: 2, scrollTop: 0},
            {index: 3, scrollTop: 0}
        ]
    );
    console.log(judgleStatus)
    useEffect(() => {
        if (blockScrollPositions) {
            blockScrollPositions.forEach(({index, scrollTop}) => {
                if (blockRefs.current[index]) {
                    blockRefs.current[index].scrollTop = scrollTop;
                }
            });
        }
    }, [figureData, id]);

    useEffect(() => {
        localStorage.setItem(`blockScrollPositions-${id}`, JSON.stringify(blockScrollPositions));
    }, [blockScrollPositions]);

    const handleScroll = (index, scrollTop) => {
        setBlockScrollPositions(prevBlockScrollPositions => {
            const updatedBlockScrollPositions = [...prevBlockScrollPositions];
            updatedBlockScrollPositions[index] = {index, scrollTop};
            return updatedBlockScrollPositions;
        });
    };

    useEffect(() => {
        if (socketData?.method === "update_user_figure") {
            const getUpdateScor = async () => {
                try {
                    const res = await api().get(`/figure/figure_brigade/user_scores/${socketData?.application_figure_id}/${socketData?.figure_id}`)
                    setUpdateInput(!updateInput)

                    handleScroll(res?.data?.scores[0]?.number_brigade - 1, (res.data.sort - 1) * 103);

                    console.log(res?.data);

                    setFigureData({
                        ...figureData,
                        figure: figureData?.figure?.map((el) => el?.figure_id === socketData?.figure_id ? ({
                            ...el,
                            users: el?.users?.map((user) => user?.user_id === res?.data?.user_id ? ({
                                    ...user,
                                    scores: res?.data?.scores,
                                    result_figure: res?.data?.result_figure,
                                    penalty: res?.data?.penalty,
                                    is_processing_discipline_figure_id: res?.data?.is_processing_discipline_figure_id
                                }) : user
                            )
                        }) : el)
                    })
                } catch (e) {
                    console.log(e)
                    toast.error('Ошибка')
                }

            }
            getUpdateScor()

        }
    }, [socketData, figure])


    // console.log(figureData)
    return (
        <div className={s.content}>
            <div className={s.figure_content}>
                {figureData?.figure?.map((fig, ind_box) => {
                    const sort_data = fig.users?.slice()?.sort((a, b) => a.sort - b.sort)
                    // console.log(JSON.parse(JSON.stringify(fig.users?.find(f => f.user_id == 46))))
                    return <div className={s.figure_box} key={fig?.figure_id}
                                ref={el => blockRefs.current[ind_box] = el}
                                onScroll={e => handleScroll(ind_box, e.target.scrollTop)}>
                        <div className={s.figure_box_header}>
                            <p className={s.figure_box_header_title}>{fig.figure_name}</p>
                            <p
                                className={classNames(
                                    s.figure_box_header_title,
                                    judgleStatus.coach_type_5?.find((f) => f.coach_type_number === fig.brigade_number)?.status === 'disconnect' && s.disconnect,
                                    judgleStatus.coach_type_5?.find((f) => f.coach_type_number === fig.brigade_number)?.status === 'connect' && s.connect,
                                )}
                                onDoubleClick={() => {
                                    setOpenDialogCustom(true)
                                    setCoachTypeId(5)
                                    setCoachTypeNumber(fig.brigade_number)
                                }}
                            >
                                {fig.brigade_name}
                            </p>
                            <p className={s.figure_box_header_title}>{fig.figure_start}</p>
                        </div>

                        <div className={s.figure_box_jud}>
                            <div></div>
                            <div>Jd 1</div>
                            <div>Jd 2</div>
                            <div>Jd 3</div>
                            <div>Jd 4</div>
                            <div>Jd 5</div>
                            <div>Jd 6</div>
                            <div>Jd 7</div>
                            <div style={{minWidth: '82px'}}>Total</div>
                            <div style={{minWidth: '62px'}}>Penalty</div>
                        </div>

                        <div className={s.figure_box_content}>
                            {sort_data?.map((user, ind) => {
                                const sort_data_user = user?.scores?.slice()?.sort((a, b) => a?.number_coach - b?.number_coach)
                                const filtered_value_without = sort_data_user.filter((f) => f.value !== null)
                                const sum = filtered_value_without.reduce((acc, inc) => acc = acc + +inc.value, 0)

                                const littleArray = Array.from({length: 7 - sort_data_user.length}, (a, b) => a++)

                                return <div className={s.figure_box_content_item} key={`user-${user.user_id}`}>
                                    <div className={s.figure_box_content_item_top}>
                                        <div className={s.figure_box_content_item_number} style={{
                                            background: user.is_processing_discipline_figure_id !== null && 'rgb(134, 183, 96)'
                                        }}>
                                            <p style={{
                                                color: user.is_processing_discipline_figure_id !== null && '#ffffff'
                                            }}>{user?.draw}</p>
                                        </div>
                                        <div className={s.figure_box_content_item_name}>
                                            <p>{user?.user_name}</p>
                                        </div>
                                        <div className={s.figure_box_content_item_city}>
                                            <p>{user?.user_command || 'Город'}</p>
                                        </div>
                                    </div>

                                    <div className={s.figure_box_content_item_sub}>
                                        <div style={{minWidth: '50px'}}></div>
                                        {sort_data_user.map((score, ind) =>
                                            <InputBox index_box={ind_box}
                                                      updateInput={updateInput}
                                                      score={score}
                                                      user_id={user.user_id}
                                                      id={score.score_figure_id}
                                                      value={score.value}
                                                      key={`score-${score?.number_coach}`}/>)}
                                        {littleArray.map((el) => <div style={{minWidth: '50px'}}/>)}
                                        <div style={{minWidth: '82px'}}
                                             className={s.score_full}>{!user?.result_figure ? '' : (+user?.result_figure).toFixed(4)}</div>
                                        <InputBox index_box={ind_box}
                                                  updateInput={updateInput}
                                                  id={user.user_id}
                                                  value={user.penalty}
                                                  penalty={true}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
});

export default FigureBox2;
